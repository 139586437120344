import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Navbar from "../components/Navbar"
import Banner from "../components/Banner"
import ContactSection from "../components/ContactSection"
import FooterSection from "../components/FooterSection"

const imprint = () => {
  return (
    <>
      <Layout>
        <SEO title="Pandigita - An App Develment Platform" />
        <Navbar hideItem="hide" />
        <Banner />
        <section className="page-section">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="page-title">
                  <h2>Imprint/Impressum</h2>
                </div>
                <div className="page-content">
                  {/* <div className="separate-section">
                    <h3>Official Representative</h3>
                    <p>Florian Rücker - Managing Director</p>
                  </div> */}
                  <div className="separate-section">
                    <h3>Company & Address</h3>
                    <address>
                      Pandigita is a brand by <br />
                      Exponential Age LLC <br />
                      30 North Gould Street, Ste R <br />
                      Sheridan, WY 82801 <br />
                      USA
                    </address>
                  </div>
                  <div className="separate-section">
                    <h3>Contact</h3>
                    <address>
                      We love to hear from you. <br />
                      Phone: +49 15678741705 / +1 307 2073967 <br />
                      Email: contact@pandigita.com <br />
                    </address>
                  </div>
                  {/* <div className="separate-section">
                    <h3>Exponential Age LLC Sole Owner</h3>
                    <p>Florian Rücker</p>
                  </div> */}
                  <div className="separate-section">
                    <h3>Liability for content</h3>
                    <p>
                      As a service provider we are responsible for our own
                      contents on these pages. However, we are not obliged to
                      monitor transmitted or stored third-party information or to
                      investigate circumstances that indicate illegal activity by
                      third parties. Obligations to remove or block the use of
                      information in accordance with general laws remain
                      unaffected by this. However, liability in this respect is
                      only possible from the time of knowledge of a concrete
                      violation of the law. As soon as we become aware of such
                      infringements, we will remove the content immediately.
                    </p>
                  </div>
                  <div className="separate-section">
                    <h3>Liability for links</h3>
                    <p>
                      Our offer may contain links to external websites of third
                      parties, over whose contents we have no influence.
                      Therefore, we cannot assume any liability for these external
                      contents. The respective provider or operator of the pages
                      is always responsible for the contents of the linked pages.
                      The linked pages were checked for possible legal
                      infringements at the time of linking. Illegal contents were
                      not recognisable at the time of linking.
                    </p>
                    <p>
                      A permanent control of the contents of the linked pages is
                      not reasonable without concrete evidence of an infringement.
                      As soon as we become aware of any legal infringements, we
                      will remove such links immediately.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <ContactSection />
        <FooterSection />
      </Layout>
    </>
  )
}

export default imprint
